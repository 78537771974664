.run-now-backdrop {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(to bottom, rgba(33, 33, 33, 0.8), rgba(33, 33, 33, 0.8));
    z-index: 1000000;
    &.in-active {
        background-image: none;
        background-color: transparent;
        pointer-events: none;
    }
    .run-now-message {
        width: 242px;
        height: 62px;
        margin: 31px 237px 6px 60px;
        padding: 8px;
        background-color: white;
        position: absolute;
        bottom: 60px;
        display: flex;
        align-content: center;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: space-between;
        -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
        pointer-events: all !important;
        .running-query {
            font-size: 15px;
            font-weight: 500;
            line-height: 15px;
            color: #212121;
            display: inline-block;
        }
        .cancel {
            margin-right: -40px;
        }
        .cancel-button {
            background-color: transparent;
            display: flex;
            padding: 2px 2px 2px 14px;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            align-items: center;
            border: 0px solid transparent;
            &.hide {
                display: none !important;
            }
            .ga-close01 {
                cursor: pointer;
                font-size: 17px;
            }
        }
    }
}
