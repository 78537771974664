// Overriding styles applied from bootstrap and masterstyle.scss
.df-share-modal {
  .MuiOutlinedInput-root fieldset legend {
    width: auto !important;
  }
  .MuiOutlinedInput-root .MuiOutlinedInput-input {
    border: none;
  }
  .MuiAutocomplete-popper .MuiAutocomplete-listbox .MuiMenuItem-root .MuiCheckbox-root{
    margin-top: 7px;
  }  
  .MuiAutocomplete-root .MuiFormControl-root .MuiFormHelperText-root{
    font-size: 12px;
  }
}
