@import "./var/palette";

.invalid-form-controller {
    &.input {
        color: $errorText !important;
        border: solid 2px $error !important;
        &::after {
            color: $errorText !important;
            border: solid 2px $error !important;
        }
    }
}

.input-box,
.input {
    &.invalid,
    &.invalid:focus,
    &.invalid:active {
        color: $errorText !important;
        border: solid 2px $error !important;
        background-color: #fdf3f2;
        &::after {
            color: $errorText !important;
            border: solid 2px $error !important;
            background-color: #fdf3f2;
        }
    }
}

.description {
    color: $gray1 !important;
    font-size: 14px;
    &.invalid {
        color: $errorText !important;
    }
}
