@import "./var/palette";
.copy-modal {
	.button-row {
		.btn {
			margin-left: 10px !important;
			padding: 6.5px 20px;
		}
		.btn.btn-disabled {
			padding: 8.5px 20px;
		}
	}
	.prompt-title {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		color: #002856;
	}
	input {
		width: 100%;
	}
}




















.shared-search-modal {
	.modal-style {
		width: 670px;
		padding: 0;
		top: 115px;
	}
	.background-f4f4f4 {
		background: #f4f4f4;
	}
	.heading3 {
		margin-top: 0;
		padding: 15px;
		margin-bottom: 0;
		border-bottom: 1px solid #d3d3d3;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		color: #002856;
	}
	.shared-search-modal-confirmation {
		.modal-header {
			h3 {
				color: #002856;
			}
		}
	}
	.roller-content {
		.client-name-border {
			border-top: solid 1px #979797;
			position: absolute;
			width: 400px;
		}
		.ga-checkbox {
			margin-top: 10px;
		}
		li {
			&:hover {
				background-color: #e5edfa;
			}
		}
	}
	.conditional-tooltip-ellipsis.truncated {
		&:hover {
			>.conditional-tooltip {
				margin-top: 10px;
			}
		}
	}
	.clientList {
		li {
			padding: 10px 15px;
			line-height: 18px;
			cursor: pointer;
			color: #212121;
		}
		li.allclients {
			div.clientNameHolder {
				border-bottom: 1px solid #979797;
				padding-bottom: 10px;
			}
			padding-bottom: 0px;
		}
	}
	.userList {
		li {
			.clientusercontainer {
				border-top: 1px solid #979797;
				display: inline-flex;
				width: 100%;
			}
			&:first-child {
				.clientusercontainer {
					border: 0px;
				}
			}
			display: inline-flex;
			line-height: 36px;
			padding: 0px 18px;
			color: #212121;
			width: 100%;
			cursor: pointer;
		}
	}
	.parameter-pill-text {
		font-size: 11px;
		max-width: 105px;
		padding-right: 7px;
		display: inline-block;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.pillBoxLogicIcon.ga-check02 {
		color: #0052d6;
		font-size: 15px;
		vertical-align: top;
	}
	.ga-subtract01 {
		color: #ba413a;
	}
	.heading4 {
		margin: 0px;
		margin-top: 15px;
		padding-left: 0;
		color: #002856;
	}
	#RollerInput {
		.ga-check02.unchecked {
			&:before {
				content: none;
			}
		}
		.ga-check02.checked {
			&:before {
				position: absolute;
			}
		}
	}
	.selected-box-style {
		#RollerInput {
			input {
				border: solid 2px #0052d6;
				background-color: #e5edfa;
				color: #0052d6;
				font-weight: 500;
			}
		}
	}
	.heading4.shared-search-message-label {
		width: 100%;
		clear: both;
	}
	.clientFilter {
		.roller.roller-open {
			width: 330px;
		}
	}
	.userFilterAdvanced {
		.roller.roller-open {
			width: 420px;
		}
	}
	.textarea {
		width: 100% !important;
		margin-bottom: 20px;
		border: solid 2px #d3d3d3;
		border-radius: 0;
		height: 120px;
	}
	.col-xs-9 {
		padding-left: 15px;
		padding-right: 15px;
		height: 100%;
	}
	.button-row {
		margin-bottom: 15px;
		.checkbox-label {
			display: inline;
		}
		.ga-checkbox {
			margin-top: 10px;
		}
	}
	.label-width {
		display: inline;
	}
	.col-xs-3 {
		height: 325px;
		overflow-y: auto;
		overflow-x: hidden;
	}
	.btn-disabled {
		padding: 8.5px 20px;
	}
	.ga-Shared-paper-plane {
		margin-top: 30px;
		text-align: center;
		display: block;
		font-size: 89px;
		color: #00a76d;
	}
	.pills-inline {
		.darktooltip+.tooltip.place-bottom {
			margin-top: -2px !important;
		}
	}
	.modal-header {
		.darktooltip+.tooltip.place-bottom {
			margin-top: 2px !important;
		}
	}
	.userlist {
		.pillbox {
			.darktooltip+.tooltip.place-bottom {
				margin-top: 6px !important;
			}
		}
	}
	.username {
		.darktooltip+.tooltip.place-bottom {
			margin-top: -8px !important;
		}
	}
	.pillbox {
		.darktooltip+.tooltip.place-bottom {
			margin-top: 8px !important;
		}
	}
	.shared-search-modal-form {
		.category-pills-container {
			margin-bottom: 15px;
			.parameter-pill-text {
				font-size: 11px;
				line-height: 19px;
				color: #212121;
				font-weight: 400;
			}
			.parameter-pill-text.is-group {
				font-weight: 500;
				font-style: italic;
			}
			.category-name {
				font-size: 14px;
				line-height: 19px;
				color: #002856;
				font-weight: 500;
			}
		}
	}
}
.shared-search-modal-confirmation {
	.heading3 {
		border-bottom: none;
	}
	.button-row {
		float: right;
		margin-right: 15px;
	}
	.btn-link {
		text-decoration: underline;
		font-weight: 400;
		padding-right: 15px;
		padding-bottom: 13px;
	}
}
.selected-criterias {
	padding: 15px;
}
#RollerInput {
	input {
		width: 100%;
		margin-left: 0;
	}
}
.pillbox {
	display: inline-block;
	font-size: 11px;
	line-height: 17px;
	background: #e0e0e0;
	padding: 5px;
	margin-right: 5px;
	margin-top: 5px;
	.ga-close01 {
		margin-left: 5px;
		font-size: 17px;
		color: #212121;
		vertical-align: middle;
		&:hover {
			cursor: pointer;
			text-decoration: none !important;
		}
	}
}
.input-multiple-box {
	max-height: 115px;
	clear: both;
	padding-top: 5px;
	margin-bottom: 5px;
	overflow-y: auto;
	overflow-x: hidden;
}
.shared-contacts-items {
	.row {
		&:hover {
			color: #e5edfa;
			cursor: pointer;
			background: #e5edfa;
			.ga-check02.unchecked {
				color: #e5edfa;
				cursor: pointer;
				background: #e5edfa;
			}
		}
	}
	.username {
		display: inline-block;
		font-weight: 400;
		color: #212121;
		width: 85%;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		vertical-align: -9px;
	}
	.ga-checkbox {
		margin-left: 15px;
	}
	.heading3 {
		padding: 0;
		padding-bottom: 7.5px;
		border: none;
		line-height: 19px;
		font-weight: 400;
		color: #212121;
		font-size: 14px;
	}
	.ga-check02.unchecked {
		color: #ffffff;
	}
}
.shared-search-modal-form {
	.modal-body {
		display: flex;
		padding: 0px;
	}
	.roller {
		margin-top: 39px !important;
	}
	#RollerInput {
		width: 100%;
		float: left;
	}
}
.ga-Shared-paper-plane+.button-row {
	margin: 0px;
}
.error-message {
	color: #de0a01;
}
.userlist {
	padding-left: 15px;
	padding-right: 15px;
	max-height: 253px;
	overflow-y: auto;
	overflow-x: hidden;
}
.copy-modal {
	.darktooltip+.tooltip.place-bottom {
		margin-top: 2px !important;
	}
}

















#modalContainer {
	.label-width {
		display: contents;
		width: 88%;
	}
	p.criteriaIconSec {
		text-align: center;
		font-size: 11px;
		font-weight: 500;
	}
	.checkfilter {
		margin-top: 27px;
	}
	.button-row {
		text-align: right;
		margin-top: 15px;
	}
	.criteriaIconSec {
		>i {
			margin-left: 5px;
		}
	}
	.modal-header {
		// padding: 0 0 10px !important;
		// margin: 0;
		// border-bottom: none;
	}
	.modal-style {
		h3 {
			padding: 0;
			margin: 0;
		}
		.checkbox-label {
			margin: 0;
			text-align: left;
			.label-width {
				font-size: 13px;
				line-height: 16px;
			}
		}
	}
}
.exclude-icon {
	color: #de0a01;
}
.button-row {
	.btn {
		margin-left: 10px !important;
		padding: 6.5px 20px;
	}
}
.modal-style {
	.checkbox-label {
		i {
			margin-right: 5px;
		}
	}
	z-index: 10000;
}
.checkbox-label {
	.ga {
		vertical-align: top;
		margin-top: 2px;
	}
}
.backdrop-style {
	z-index: 9999;
}