.ethnic-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10003;
}

.ethnic-popup-content {
  margin-top: 272px;
  margin-bottom: 20px;
  background: #fff; /* Shorthand property for background-color */
  padding: 60px;
  padding-bottom: 15px;
  padding-top: 15px;
  border-radius: 5px;
  border-radius: 5px;
  position: relative;
  max-width: 800px;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


.ethnic-popup-content h2 {
  color: #002f6c; /* Dark blue heading color */
  text-align: left;
  margin-bottom: 20px;
}

.ethnic-coverage-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.ethnic-coverage-table th {
  background-color: #002f6c; /* Dark blue background for header */
  color: #fff;
  padding: 10px;
  text-align: left;
}

.ethnic-coverage-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
  white-space: nowrap;
  color: #000000;
}

.ethnic-previous-name {
  font-size: 0.85em;
  color: #505050;
  font-style: italic; /* Make the previous name italic */
}

.ethnic-know-more {
  color: #000000;
}

.ethnic-popup-content a {
  color: #002f6c;
  text-decoration: none;
}

.ethnic-popup-content a:hover {
  text-decoration: underline;
}

.ethnic-close-btn {
  color: #fff;
  margin-top: -50px ;
  margin-bottom: 29px ;
  margin-left: 513px;
}
