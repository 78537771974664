//BEGIN RESERVED COLOR PALETTE

$newGartnerBlue: #002856;
$tangerine : #ff540a;
$newGartnerBlueHover: #011c3c;
$tangerineHover : #d1460b;
$hover: #e5edfa;
$rose : #e81159;
$rose1 : #af0d43;
$sky : #009ad7;
$steel : #6f7878;
$graphite : #535a5a;
$lemon : #fec10d;
$link : #0052d6;
$border : #d3d3d3;
$background : #f4f4f4;
$error : #de0a01;
$errorText : #e20801;
$sucess : #00a76d;
$warning : #f5ab23;
$black : #000000;
$white : #ffffff;
$gray1 : #212121;
$gray2 : #414141;
$grey3 : #616161;
$gray4 : #c0c0c0;
$gray5 : #e0e0e0;
$gray6 : #f0f0f0;
$gray7 : #fafafa;

//data color group 1
$DCG1Color1 : #0a3f6b;
$DCG1Color2 : #00aeef;
$DCG1Color3 : #7fd6f7;
$DCG1Color4 : #416428;
$DCG1Color5 : #9cc84b;
$DCG1Color6 : #cde3a5;
$DCG1Color7 : #46295b;
$DCG1Color8 : #8d64aa;
$DCG1Color9 : #c6b1d4;
$DCG1Color10 : #00566b;
$DCG1Color11 : #2bc4b6;
$DCG1Color12 : #95e1da;

//data color group 2
$DCG2Color1 : #8d64aa;
$DCG2Color2 : #46295b;
$DCG2Color3 : #c6b1d4;
$DCG2Color4 : #2bc4b6;
$DCG2Color5 : #00566b;
$DCG2Color6 : #95e1da;
$DCG2Color7 : #00aeef;
$DCG2Color8 : #0a3f6b;
$DCG2Color9 : #7fd6f7;
$DCG2Color10 : #9cc84b;
$DCG2Color11 : #416428;
$DCG2Color12 : #cde3a5;

//data color group 3
$DCG3Color1 : #2bc4b6;
$DCG3Color2 : #00566b;
$DCG3Color3 : #8d64aa;
$DCG3Color4 : #46295b;
$DCG3Color5 : #00aeef;
$DCG3Color6 : #0a3f6b;
$DCG3Color7 : #7fd6f7;
$DCG3Color8 : #416428;
$DCG3Color9 : #9cc84b;
$DCG3Color10 : #f9d889;
$DCG3Color11 : #f4b213;
$DCG3Color12 : #92451c;

//Data Heat Map
$DHMcolor1 : #628846;
$DHMcolor2 : #9cc84a;
$DHMcolor3 : #f4ce73;
$DHMcolor4 : #e79762;
$DHMcolor5 : #ba413a;

//Data Heat Map text
$DHMTcolor1 : #416428;
$DHMTcolor2 : #6a9d0c;
$DHMTcolor3 : #e9ae2a;
$DHMTcolor4 : #d36b00;
$DHMTcolor5 : #94332d;

$transparent : transparent;
//END OF RESERVED COLOR PALETTE