// .fill {
//   min-height: 55vh;
// }

body #root {
	padding-right: 5px;
}

::-webkit-scrollbar {
	width: 5px !important;
}

.root-loading{
	width: fit-content;
  	height: fit-content;
  	margin: calc((80vh / 2)) auto;
}
.hide {
	display: none;
}

.row {
	margin: 0;
}

.contentcontainer {
	margin-left: 60px;
	// min-width: 1000px;
	// max-width: 1430px;
	// margin: 0px auto;
}

.parameter-panel-validation-error {
	margin-right: 16px !important;
}

.modal-line-separator{
	margin-bottom: 10px !important;
}

.view-as .view-as-modal-input-container {
	input:active, input:focus {
	  border: none!important;
	}
}

.universal-filter-container .parameter-container .parameter-panel .pills-container .pill-container .pill{
	position: relative;
	.darktooltip+.tooltip.place-bottom{
	position: absolute;
    top: 25px !important;
    left: 5px !important;
	}
}

.ga.ga-curr-pln:before,.ga.ga-curr-sek:before,.ga.ga-curr-dkk:before{
	display: none;
}

.ga.ga-curr-pln{
	display: inline-block;
	background-image: url( "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='rgb(97, 97, 97)' viewBox='0 0 28 28'%3E%3Cpath d='M14 0C6.28 0 0 6.28 0 14C0 21.72 6.28 28 14 28C21.72 28 28 21.72 28 14C28 6.28 21.72 0 14 0ZM14 26.0133C7.37333 26.0133 1.98667 20.6267 1.98667 14C1.98667 7.37333 7.37333 1.98667 14 1.98667C20.6267 1.98667 26.0133 7.37333 26.0133 14C26.0133 20.6133 20.6267 26.0133 14 26.0133Z'/%3E%3C/svg%3E"),
	url( "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='rgb(97 97 97)' viewBox='0 0 28 28'%3E%3Cpath d='M7.42667 20.7467V19.4L13.6267 12.24C12.92 12.28 12.2933 12.3067 11.76 12.3067H7.8V10.9733H15.7733V12.0667L10.4667 18.2667L9.45333 19.4267C10.2 19.36 10.8933 19.3333 11.5467 19.3333H16.08V20.7733H7.42667V20.7467ZM17.7067 15.4533L16.52 16.48V15.0267L17.7067 14V7.23999H19.3467V12.5867L20.5733 11.5333V12.9867L19.3467 14.04V20.7467H17.7067V15.4533Z'/%3E%3C/svg%3E") ;
}
.ga.ga-curr-sek,.ga.ga-curr-dkk{
	display: inline-block;
	background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' fill='rgb(97, 97, 97)' viewBox='0 0 19.84 19.84'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B stroke-width: 0px; %7D %3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M10.76,7.81h-1.42l-1.9,2.23c-.19.23-.41.51-.57.74h-.03v-5.99h-1.16v9.49h1.16v-2.43l.6-.67,2.23,3.1h1.43l-2.85-3.81,2.5-2.66Z'/%3E%3Cpath class='cls-1' d='M13.25,9.08h-.05l-.04-1.27h-1.03c.04.6.05,1.26.05,2.02v4.45h1.16v-3.45c0-.2.03-.39.05-.55.16-.88.75-1.51,1.58-1.51.16,0,.28.01.4.04v-1.11c-.11-.03-.2-.04-.33-.04-.79,0-1.5.55-1.79,1.42Z'/%3E%3Cpath class='cls-1' d='M9.92,0C4.45,0,0,4.45,0,9.92s4.45,9.92,9.92,9.92,9.92-4.45,9.92-9.92S15.39,0,9.92,0ZM9.92,18.64c-4.81,0-8.72-3.91-8.72-8.72S5.11,1.2,9.92,1.2s8.72,3.91,8.72,8.72-3.91,8.72-8.72,8.72Z'/%3E%3C/svg%3E");
}
.legend-icon.ga.ga-curr-usd::before{
	display: none;
}
.legend-icon.ga.ga-curr-usd{
	height: 20px;
	width: 20px;
	display: inline-block;
	background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='rgb(97, 97, 97)' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10 0C15.5072 0 20 4.49275 20 10C20 15.5072 15.5072 20 10 20C4.49275 20 0 15.5072 0 10C0 4.49275 4.49275 0 10 0ZM10 1C5.01653 1 1 5.01653 1 10C1 14.9835 5.01653 19 10 19C14.9835 19 19 14.9835 19 10C19 5.01653 14.9835 1 10 1Z' fill='%23414141'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.94 6V7.65H15.91V13.35H6.97V11.7H5V6H13.94ZM6 7V10.7H12.94V7H6ZM7.97 12.35H14.91V12.34V8.64H13.94V11.7H7.97V12.35ZM8.45996 8.84999C8.45996 8.33999 8.86996 7.92999 9.37996 7.92999C9.88996 7.92999 10.3 8.33999 10.3 8.84999C10.3 9.35999 9.88996 9.76999 9.37996 9.76999C8.86996 9.76999 8.45996 9.35999 8.45996 8.84999Z' fill='%23414141'/%3E%3C/svg%3E%0A") !important;
}
span[class*='-curr-']:before{
	display: none !important;
}
span[class^='ga ga-curr-']{
	display: inline-block !important;
	background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' fill='rgb(97, 97, 97)' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10 0C15.5072 0 20 4.49275 20 10C20 15.5072 15.5072 20 10 20C4.49275 20 0 15.5072 0 10C0 4.49275 4.49275 0 10 0ZM10 1C5.01653 1 1 5.01653 1 10C1 14.9835 5.01653 19 10 19C14.9835 19 19 14.9835 19 10C19 5.01653 14.9835 1 10 1Z' fill='%23414141'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.94 6V7.65H15.91V13.35H6.97V11.7H5V6H13.94ZM6 7V10.7H12.94V7H6ZM7.97 12.35H14.91V12.34V8.64H13.94V11.7H7.97V12.35ZM8.45996 8.84999C8.45996 8.33999 8.86996 7.92999 9.37996 7.92999C9.88996 7.92999 10.3 8.33999 10.3 8.84999C10.3 9.35999 9.88996 9.76999 9.37996 9.76999C8.86996 9.76999 8.45996 9.35999 8.45996 8.84999Z' fill='%23414141'/%3E%3C/svg%3E%0A") !important;
}

