@import "./var/palette";

header {
  .main-container {
    display: table;
  }

  & .navbar-static-top {
    height: 63px;
  }

  &.navbar {
    padding: 0;
    border: 0px;
  }

  .toolbar {
    float: right;
    display: flex;
    align-items: center;

    .tn-announcement-icon {
      position: relative;
      top: 0;
    }

    .tn-announcement-banner {
      position: fixed;
      top: 0;
      left: 0;
    }

    .nav {
      a {
        font-size: 13px;
        text-decoration: none !important;
        color: #002856 !important;
      }
    }

    .header-top-icon {
      color: $newGartnerBlue;
    }

    .navbar-right {
      margin: 0px;
      padding: 12px 0;
      border-bottom: 4px solid $newGartnerBlue;
    }
  }

  .navbar-brand {
    .tnp-nav-logo-text {
      cursor: pointer;
      margin-left: 0 !important;
    }
  }
}


.top-header-container .header-wrapper {
  display: flex;
  align-items: center;
  padding: 12px 20px 12px 60px;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
  background: #fff;
  height: 60px;
}

.top-header-container .header-wrapper .tn-announcement-banner {
  position: fixed;
  top: 0;
  left: 0;
}

.top-header-container .header-wrapper .header-content {
  height: 22px;
  padding-left: 20px;
}

.top-header-container .header-wrapper .tn-universal-search-container {
  margin: 0 auto;
}

.top-header-container .header-wrapper .tn-universal-search-container .MuiFormControl-root {
  border-radius: 9px;
  outline: 2px solid #C7CAE0;
  margin-top: 0;
  height: 36px;
}

.tn-universal-search-container .MuiFormControl-root .MuiInputBase-root {
  padding: 8px 60px 8px 20px;
  height: 36px;
}

.tn-universal-search-container .MuiFormControl-root .MuiInputBase-root .MuiInputAdornment-root {
  z-index: 0;
  margin: 0;
  position: absolute;
  right: 20px;
}

.top-header-container .header-wrapper .tn-universal-search-container .MuiFormControl-root .MuiInput-root input:active,
.top-header-container .header-wrapper .tn-universal-search-container .MuiFormControl-root .MuiInput-root input:focus-visible {
  border: none !important;
}

.tn-universal-search-popper {
  margin-left: 15px !important;
  margin-top: -6px !important;
  background-color: #ffff;
}

@media (max-width: 1240px) {
  .top-header-container .header-wrapper .tn-universal-search-container {
    width: 575px !important;
  }

  .tn-universal-search-popper .popper-content {
    width: 575px !important;
  }
}

@media (min-width: 1240px) {
  .top-header-container .header-wrapper .tn-universal-search-container {
    width: 661px !important;
  }

  .tn-universal-search-popper .popper-content {
    width: 661px !important;
  }
}

.top-header-container .tn-universal-search-container input::-ms-input-placeholder {
  opacity: 1;
}

.top-header-container .tn-universal-search-container .MuiInputBase-input,
.top-header-container .tn-universal-search-container input::placeholder {
  opacity: 1;
}

.top-header-container .tn-universal-search-container input:focus::-ms-input-placeholder {
  color: transparent !important;
  opacity: 0;
}

.top-header-container .tn-universal-search-container input:focus::placeholder {
  color: transparent !important;
  opacity: 0;
}

.tn-universal-search-container .MuiInputBase-root .MuiInputAdornment-root .tn-icon.icon-magnifying-glass-search {
  font-size: 20px;
}