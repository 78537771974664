#RollerInput {
	pointer-events: auto;
	position: relative;
	height: 36px;
	z-index: 2;
	>div {
		position: absolute;
		box-shadow: 0 1px 3px 0 rgba(97, 97, 97, 0.28);
		border: solid 2px #d3d3d3;
		background-color: #FFFFFF;
	}
	.roller {
		-webkit-transition: all 500ms;
		-moz-transition: all 500ms;
		-ms-transition: all 500ms;
		-o-transition: all 500ms;
		transition: all 500ms;
		display: inline-block;
		overflow-y: hidden;
		left: 0;
	}
	.roller.roller-close {
		max-height: 0px;
		border-bottom-width: 0px;
		border-top-width: 0px;
		visibility: collapse;
	}
	.roller.roller-open {
		display: inline-block;
		max-height: 206px;
		overflow-y: auto;
		overflow-x: hidden;
	}
}
.profile-search {
	#RollerInput {
		>div {
			margin-top: 39px;
		}
	}
}
.error {
	border: solid 2px #de0a01;
	background-color: #fdf3f2;
}
