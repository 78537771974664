.step {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.step.last-right-step{
    flex: 0;   
}

.step-left-connector {
	flex: 1;
	height: 1px;

	background-color: rgba(0, 0, 0, 0.3);

}

.step-number {
	/* Center the content */
	align-items: center;
	display: flex;
	justify-content: center;

	/* Rounded border */
	background-color: #00a76d;
	border-radius: 9999px;
	height: 34px;
	width: 34px;

    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #002856;

    position: relative;
      
}

.step-number.current{
    background-color: transparent;
    border: solid 3px #00a76d;
}


.step.current ~ .step>.step-right-connector,
.step.current ~ .step .step-number,
.step.current>.step-right-connector {
    background-color:#d8d8d8;
}

.step-right-connector {
	flex: 1;
    height: 3px;
    background-color: #00a76d;
}

.step-text {
    position: absolute;
    top: 45px;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #002856;
    white-space: nowrap;
  }

  .step-description{
	margin-left: 4px;
    margin-right: 4px;
  }