@import "./var/palette";

.icon-animation:hover:before{
  color: $newGartnerBlue!important;
}
.icon-animation:before {
  color: $newGartnerBlue;
}
.tooltip-button .tooltip-button-label {
  position: absolute;
  background-color: rgba(97, 97, 97, 0.9);
  padding: 3px;
  color:$white;
  font-size: 12px;
  line-height: 1.25;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  transition: visibility 150ms ease-in, opacity 150ms ease-in;
}

.tool-tip-button.medium .tooltip-button-label {
  margin-top: 36px;
  margin-left: -15px;
}
#stickymainsContainer .stickyNav-li-icon.tool-tip-button .tooltip-button-label {
  margin-top: 25px;
  margin-left: -35px;
}
.row-flyout-content .tooltip-button-label {
  margin-left: -42px;
}
.tool-tip-button.big .tooltip-button-label {
  margin-top: 40px;
  margin-left: -10px;
}
.tool-tip-button.small .tooltip-button-label {
  margin-top: 40px;
  margin-left: -10px;
}
.tool-tip-button.big .icon-animation {
  padding-top: 7px;
  font-size: 28px;
  width: 44px;
  height: 44px;
}

.tooltip-button{
  float: left;
  width: 25px;
}

.tooltip-button:hover .tooltip-button-label {
  visibility: visible;
  opacity: 1;
}
.tooltip-button-label.excellDownload {
  top: 35px;
}


.icon-animation, .icon-animation:after, .icon-animation:before {
  box-sizing: border-box;
  -webkit-transition: all .15s;
  -o-transition: all .15s;
  transition: all .15s;
}


.row-button {
  height: 38px;
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  align-items: center;
  & .icon-animation{
    box-sizing: border-box;
    transition: all .15s;
    text-align: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    font-size: 15px;
    &:hover{
      background : none;
      &:after {
        background: rgba(0,40,86,.2);
        border-radius: 50%;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        width: 30px;
        height: 30px;
      }
    }
    &:after {
      position: absolute;
      display: block;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      -webkit-transform: scale(.3);
      -ms-transform: scale(.3);
      transform: scale(.3);
    }
  }
  & .ga-share{
    &:before{
      position: relative;
      top: 3px;
    }
  }
  & .data-feed-delete-confirmation {
    //I dont see this implemented yet
    display: none !important;
    top: 25px;
    width: 276px;
    height: 122px;
    cursor: default;
    visibility: hidden;
    pointer-events: auto;
    right: auto;
    &.button-row>.btn {
      float: right;
    }
  }
  & .popover{
    border-radius: 6px;
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    line-break: auto;
  }
  & .open{
    visibility: visible;
  }
}