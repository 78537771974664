.dataFeedLoading {
  & .step-sub-container {
    background-color: rgba(255, 255, 255, 0.95);
    opacity: 0.1;
  }
}
.step-main-container {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  &.build-search {
    .step-active {
      .stickleft {
        padding-left: 0px;
        position: absolute;
        z-index: 3;
      }
    }
  }

  .step-sub-container {
    min-height: 500px;
    padding-bottom: 20px;
    .mandatory {
      padding: 0 23px 0 15px;
      border: solid 2px #de0a01;
      background-color: #fdf3f2;
    }
    &.buildsearchform {
      padding-left: 95px;
      .input-caption {
        margin-top: 20px;
      }
    }
  }

  .Dropdown-arrow {
    right: 22px;
    top: 8px;
  }

  .step-input {
    margin-bottom: 30px;
  }
  .input-caption {
    margin-bottom: 10.5px;
    margin-top: 32px;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: normal;
    color: #002856;
  }

  .step-control {
    margin-left: 25px;
  }

  .save-close-btn,
  .close-btn,
  .back-btn {
    background-color: #f4f4f4;
    border: 2px solid #f4f4f4;
    color: #23527c;
  }

  .back-btn {
    margin-left: 0px;
  }

  .save-close-btn,
  .close-btn,
  .save-continue-btn {
    float: right;
  }

  .back-btn {
    float: left;
  }

  .controls-bar-container {
    z-index: 2;
    width: 100%;
    min-height: 70px;
    box-shadow: 0 -3px 8px 0 rgba(0, 0, 0, 0.1);
    padding: 17px 0;
    position: fixed;
    bottom: 0;
    background: #ffffff;
  }

  .past-caption {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #212121;
    margin-right: 14px;
  }

  .configure-file-config.panel-container {
    padding: 0 15px 0 0;
    margin: 0 -15px 0 0;
  }

  .past-number-input {
    display: inline-flex;
  }

  .col-xs-3.fields-column {
    margin-right: 5px;
    padding-left: 0px;
  }

  .past-input-gap {
    width: 10px;
  }

  .check-box-section .label-width {
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 400;
    color: #212121;
    line-height: 18px;
  }
  .configure-file-config > span {
    margin-right: 20px;
  }

  .configure-file-config.col-xs-9 {
    padding-left: 0px;
  }

  .configure-file-config .organize-fields-panel {
    z-index: 0;
    width: 100%;
    height: 386px;
    margin-top: 10px;
    padding: 20px 10px;
    background-color: #f4f4f4;
  }

  .configure-file-config .organize-fields-panel-draggable {
    height: 350px;
  }

  .configure-file-config .organize-fields-panel.character-limit-menu-open {
    height: 311px !important;
  }

  .configure-file-config
    .organize-fields-panel.character-limit-menu-open
    .organize-fields-panel-draggable {
    height: 290px;
  }

  .configure-file-config .organize-fields-button {
    background-color: #f4f4f4;
    height: 75px;
    position: relative;
    width: 100%;
    padding: 20px 10px;
  }

  .organize-fields-button .clear-limit {
    padding: 0 15px;
    border: solid 2px #c0c0c0;
    background-color: #ffffff;
    color: #012856;
  }

  .btn-secondary-outline:hover,
  .btn-secondary-outline:active,
  .btn-secondary-outline:focus {
    color: #ffffff !important;
    background-color: #002856 !important;
    border: 2px solid #002856 !important;
  }

  .organize-fields-button .cancel-limit {
    margin-left: 12px;
    text-decoration: underline;
  }

  .organize-fields-button .save-limit,
  .organize-fields-button .clear-limit {
    width: 68px !important;
    height: 36px;
    padding: 0 15px;
    line-height: 33px !important;
    font-weight: 500;
  }

  .organize-fields-panel .with-limit .character-limit-input {
    border: solid 2px #0052d6;
    background-color: #e5edfa;
    font-weight: 600;
    color: #005cdb;
  }

  .configure-file-config a {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    line-height: 19px;
    color: #0052d6;
    margin-right: 10px;
    padding-top: 10px;
    cursor: pointer;
  }

  .check-box-section-name {
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #212121;
    margin-bottom: 9px;
  }

  .check-box-section {
    margin-bottom: 15px;
    margin-right: 20px;
  }
  .check-box-section-input .data-feed-action {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 10;
    display: none;
  }

  .limited-coverage {
    font-size: 8px;
    color: #007bff; 
    margin-bottom: 0px; 
    display: flex; /* Use flexbox for alignment */
    align-items: center; /* Vertically align items */
    margin-left: 20px;
  }

  .limited-coverage-tooltip {
    position: absolute;
    left: 0;
    bottom: -1px;
    line-height: 10px;
  }
  
  .limited-coverage-text {
    font-style: italic;
    cursor: pointer;
  }
  
  .limited-coverage-info-icon {
    font-style: normal;
    position: relative;
    left: 3px;
    top: 1px;
  }
  

.limited-coverage a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

.limited-coverage a:hover {
    text-decoration: underline;
}

/* Styles for the pop-up */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10003;
}

.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  max-width: 500px;
  width: 100%;
}

.close-btn {
  background-color: #f4f4f4;
  color: #23527c;
  border: none;
  border: 2px solid #f4f4f4;
}

.popup-body {
  margin-top: 20px;
}

  icon-animation,
  .icon-animation:after,
  .icon-animation:before {
    box-sizing: border-box;
    transition: all 0.15s;
  }

  .check-box-section-input {
    position: relative;
    height: 28px;
  }

  .check-box-section .checkbox-label {
    margin-bottom: 0px;
  }

  .check-box-section
    .check-box-section-input.normal-mode:hover
    .checkbox-label {
    background-color: #e5edfa;
  }

  .check-box-section
    .check-box-section-input.normal-mode:hover
    .data-feed-action {
    display: block;
  }

  .mandatory-message {
    height: 19px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 19px;
    letter-spacing: normal;
    color: #e20801;
  }

  .check-box-section .ga-checkbox {
    margin-right: 5px;
    margin-top: 4px;
  }

  .organize-fields-panel *:focus {
    outline: none !important;
  }

  .configure-file-config .pill-data-feed {
    height: 30px;
    border-radius: 14.5px;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 30px;
    letter-spacing: normal;
    color: #212121;
    position: relative;
    background-color: #ffffff;
    width: fit-content;
  }

  .configure-file-config .pill-data-feed:hover {
    background-color: #e5edfa;
  }

  .configure-file-config .organize-fields-panel .pill-data-feed .delete {
    display: none;
    position: absolute;
    right: -25px;
    font-size: 17px;
    height: 17px;
    width: 17px;
    color: #000000;
    cursor: pointer;
    font-weight: normal;
  }

  .configure-file-config
    .organize-fields-panel
    .draggable-pill-box:hover
    .delete {
    display: block;
  }
  span.ga.pillBoxLogicIcon.ga-close01.delete
    .configure-file-config
    .pill-data-feed:focus {
    border: 2px solid #0052d6;
    background-color: rgba(0, 97, 188, 0.1);
  }

  .configure-file-config .pill-data-feed > span {
    margin: 0 7px 0 12px;
    width: min-content;
    display: flex;
  }

  organize-fields-panel.character-limit-menu-open > div > div {
    width: auto;
  }

  .configure-file-config .ga {
    margin-left: 3px;
    object-fit: contain;
    color: #002856;
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
  }

  .configure-file-config .organize-fields-panel .ga {
    color: #0052d6;
  }

  .save-close-btn.new-field {
    width: 63px;
    height: 30px;
    line-height: 27px;
    margin-left: 5px;
    padding: 0 15px;
  }

  .btn-disabled.save-close-btn {
    color: #616161;
    background-color: #d3d3d3;
    border: none;
  }
  
  .standard-pill.pill-data-feed .container-pill-limit-label {
    float: left;
    width: max-content;
    white-space: nowrap;
  }

  .custom-fields-input .checkbox-label {
    width: auto;
  }

  .custom-fields-input {
    display: flex;
    margin-bottom: 15px;
  }

  .custom-fields-input input {
    height: 30px !important;
    width: 140px;
  }

  .icon-btn {
    display: inline-block;
    font-size: 11px !important;
    margin-top: -9px !important;
    color: #002856;
    cursor: pointer;
    vertical-align: bottom;
  }

  .icon-animation {
    text-align: center;
    width: 24px;
    height: 24px;
    padding-top: 7px;
    margin-top: -5px !important;
    cursor: pointer;
  }

  .icon-animation:hover {
    background-color: rgba(0, 40, 86, 0.2);
    border-radius: 50%;
    transform: scale(1);
    pointer-events: auto;
  }

  .icon-animation:after {
    position: absolute;
    display: block;
    content: "";
  }

  .character-limit-menu-close .input-box {
    left: 225px;
    visibility: hidden;
    display: none;
    left: 0px;
  }

  .character-limit-menu-open .pill-data-feed {
    background-color: #e5edfa;
    border: none;
  }

  .character-limit-menu-open .container-pill-limit-input .pill-data-feed {
    width: auto;
  }

  .character-limit-menu-open .input-box {
    visibility: visible;
    display: block;
  }

  .organize-fields-panel .input-box {
    float: right;
    text-align: right;
  }
  .custom-fields a {
    cursor: pointer;
  }

  .organize-fields-panel .character-limit-input {
    width: 70px;
    height: 30px;
    margin: 0 0 0 82px;
    padding: 0 !important;
    border: solid 2px #d3d3d3;
    background-color: #ffffff;
    text-align: right;
    position: absolute;
    font-weight: normal;
    font-size: 14px;
    right: 0px;
    box-sizing: border-box;
    text-align: center;
  }

  .organize-fields-panel > div {
    overflow-x: hidden;
  }

  .organize-fields-panel .organize-fields-button {
    position: absolute;
    bottom: 20px;
  }

  a.btn.disabled,
  fieldset[disabled] a.btn {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    background-color: #c0c0c0;
    border: none;
  }

  a.btn.btn-primary.save-limit {
    width: 70px;
    height: 36px;
    line-height: 36px;
    background-color: #ff540a;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-right: 0px;
  }

  .right-section,
  .left-section,
  .middle-section {
    float: left;
  }

  .middle-section {
    margin-top: 29.5px;
  }

  .middle-section:after {
    content: "";
    position: absolute;
    left: 50%;
    border-right: solid 1px #979797;
    height: 315px;
    transform: translateX(-50%);
  }

  .btn-secondary-outline:focus {
    background-color: #ffffff !important;
    color: #002856 !important;
  }

  .step-main-container .Dropdown-control {
    height: 35px;
  }

  .schedule-36-30,
  .starting-date-period input {
    height: 36px;
    margin-bottom: 30px;
  }

  .check-box-section-input.normal-mode .input-box {
    display: none;
  }

  .check-box-section-input.edit-mode .label-width,
  .check-box-section-input.edit-mode .data-feed-action {
    display: none;
  }

  .step-sub-container.data-feed-schedule .Dropdown-placeholder {
    white-space: nowrap;
  }

  .drop-down-generate-period {
    width: 235px;
  }

  .drop-down-generate-period .Dropdown-root {
    height: 35px;
  }

  .starting-date-period .react-datepicker__current-month,
  .starting-date-period .react-datepicker__navigation {
    display: none;
  }

  .schedule-calender {
    width: 335px;
    height: 300px;
    padding: 16px;
    background: #002856;
    color: #fff;
  }

  .starting-date-period .react-datepicker__day-name,
  .starting-date-period .react-datepicker__day,
  .starting-date-period .react-datepicker__time-name {
    width: 40px;
    height: 30px;
    font-size: 15px;
    line-height: 30px;
  }
  .starting-date-period .react-datepicker {
    font-size: 15px;
  }

  .data-feed-schedule .input-caption.input-caption-left-label {
    float: left;
    margin-top: 0px;
    line-height: 36px;
  }

  .data-feed-schedule .schedule-calender-input {
    position: relative;
    font-weight: 400;
    font-size: 14px;
    line-height: 36px;
    background-color: white;
    border: 2px solid #d3d3d3;
    border-radius: 0px;
    box-sizing: border-box;
    cursor: pointer;
    width: 150px;
    height: 36px;
    margin: 0 0 0 9px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #212121;
    padding: 0px;
  }

  .data-feed-schedule .schedule-calender-input .ga {
    display: unset;
    width: 15px;
    height: 17px;
    color: #012856;
    margin: 0 9px 0 0;
    font-size: 15px !important;
    vertical-align: unset;
  }

  .data-feed-schedule .schedule-calender-input:focus,
  .data-feed-schedule .react-datepicker__day:focus {
    outline: unset;
  }

  .schedule-calender-input:hover {
    background-color: #f0f0f0;
  }

  .data-feed-schedule .multi-boxs-button a {
    width: 36px;
    height: 36px;
    line-height: 36px;
    border: solid 2px #002856;
    padding: 0px;
  }

  .data-feed-schedule .multi-boxs-button a.inactive {
    border: solid 1px #d3d3d3 !important;
  }
  .data-feed-schedule .multi-boxs-button span{
    margin-left: 10px;
    color: #212121;
  }
  .data-feed-schedule .input-caption.input-caption-left-label {
    font-size: 14px;
    width: 100px;
  }

  .data-feed-schedule .schedule-calender-input {
    margin-left: 0px;
  }

  .data-feed-schedule .starting-hour-period .Dropdown-control {
    width: 95px;
    margin-right: 11px;
  }

  .data-feed-schedule .starting-hour-period a {
    margin-left: 100px;
    cursor: pointer;
  }

  .data-feed-schedule .multi-boxs-button a {
    margin-left: 0px;
  }

  .data-feed-schedule .starting-hour-period .Dropdown-root {
    float: left;
  }

  .data-feed-schedule .starting-hour-period .input-caption {
    margin-bottom: 0px;
  }

  .data-feed-schedule .starting-hour-period .normal-row {
    margin-left: 100px;
  }

  .data-feed-schedule .starting-hour-period .first-row .Dropdown-control,
  .data-feed-schedule .starting-hour-period .normal-row .Dropdown-control {
    cursor: pointer;
  }
  .data-feed-schedule .posting-only,
  .data-feed-schedule .notify-me {
    margin-top: 30px;
  }

  .data-feed-schedule .right-section .input-box {
    margin-top: 20px;
    width: 490px;
  }

  .data-feed-schedule .right-section .input-box div{
    margin-bottom: 8px;
  }

  .data-feed-schedule .right-section .input-box .email-to{
    font-size: 15px;
    font-weight: 500;
    line-height: 1.33;
    color: #002856;
  }

  .data-feed-schedule .right-section .input-box .email-limit{
    font-size: 14px;
    float: right;
    color: #212121;
  }

  .input-box .ga-info{
    color: #000000;
  }

  .ga-info .popovertooltip{
    position: absolute;
    top:5px;
    left: -30px;
    width: 280px;
    padding: 20px 40px 20px 20px;
    border: 2px solid #e0e0e0;
    background-color:#fafafa;
    box-shadow: 0 1px 3px 0 rgba(65, 65, 65, 0.28);
    text-align: left;
    font-family: "Graphik Web",Arial!important;
    font-size: 14px;
    line-height: 1.36;
    color: #212121;
  }
  .input-box .ga-info .popovertooltip{
    display: none;
  }

  .input-box .ga-info:hover .popovertooltip{
    display: block;
  }

  .data-feed-schedule .right-section .Dropdown-root {
    width: 235px;
  }

  .data-feed-schedule .right-section input.input {
    width: 320px;
  }

  .data-feed-schedule .right-section .pattern-input-end {
    width: 100px !important;
  }
  .data-feed-schedule .right-section .pattern-input-separator {
    width: 50px !important;
    line-height: 60px;
    text-align: center;
  }
  .data-feed-schedule .right-section .pattern-input {
    display: flex;
  }

  .Dropdown-control.dropdown-changed .Dropdown-arrow:before {
    color: #616161;
  }

  .Dropdown-root.is-open {
    .Dropdown-control {
      border-bottom: 0px !important;
    }
  }

  .Dropdown-control {
    background-color: transparent;
    font-weight: normal;
    color: #616161;
    border: 2px solid #d3d3d3;
    height: 36px;
  }
  .step-inactive {
    display: none;
  }

  .sub-category.check-box-section-name {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    color: #616161;
  }

  .configure-file-fields .saved-limit {
    width: max-content;
    margin-left: 8px;
  }

  .check-box-section.occupation > .check-box-section-input,
  .check-box-section.location > .check-box-section-input,
  .check-box-section.contact-information > .check-box-section-input {
    display: inline-block;
    width: 50%;
  }

  .check-box-section-input .input-box input {
    position: absolute;
    height: 25px;
    margin-left: 20px;
    width: 90%;
  }
  
  div.up-to-x-days-caption {
    margin-left: 41px;
    padding-top: 10px;
  }
}

.dfNameDuplicationModal {
  padding: 15px;
  position: relative;
}
.publishManageConfirmationModal__mainContain {
  display: flex;
}
.dfNameDuplicationModal .modal-style {
  width: 480px;
  height: 141px;
}
.dfNameDuplicationModal__title {
  color: #002856;
  font-size: 18px;
  line-height: 1.28;
  letter-spacing: normal;
  font-weight: 500;
}

.dfNameDuplicationModal__title > span {
  font-weight: 700;
  margin-right: 5px;
}

.dfNameDuplicationModal__buttonRow {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 10px 15px;
}

.dfNameDuplicationModal__buttonRow > a {
  margin-left: 15px;
}

.isSplitInput__container {
  font-size: 14px;
  line-height: 1.43;
  > input {
    margin: 15px 10px;
  }
  > .isSplitInput__container--lable {
    color: #212121;
    font-weight: 500;
  }
}

.loading-container{
  position: fixed;
  left: 45%;
  top:40%
}
.date-range-options > div{
  display: inline-block;
  vertical-align: top;
}
.date-range-options{
  visibility: hidden;
  opacity: 0;
  transition: visibility 2s, opacity 5s linear;
  width: 684px;
  float: left;
  padding: 20px 0 20px 15px ;
  border: solid 2px #d3d3d3;
}
.date-range-options.show{
  visibility: visible;
  opacity: 1;
}
.range-separator{
  font-size: 15px;
  font-weight: 500;
  line-height: 1.33;
  color: #002856;
  margin:30px 39px;
}
.calendarcontainer {
  display: inline-block;
  margin-right: 10px;
  position: relative;
  vertical-align: top;
}
.calendarcontainer .ga-calendar{
  position: absolute;
  top: 8px;
  left: 15px;
  color: #616161;
  font-size: 20px;
}
.DayPickerInput input {
  width: 140px;
  color: #212121;
  padding-left: 40px;
  padding-right: 0;
}
.DayPickerInput-Overlay {
  position: absolute;
  left: 0;
  z-index: 1;
  background: #fff;
}
.DayPicker {
  width: 291px;
  margin: 0;
  padding: 18px 15px 0;
}
.calendarcontainer .DayPicker-wrapper {
  width: 260px;
}
.calendarcontainer .DayPicker-wrapper .DayPicker-Month {
  width: 100%;
  margin: 0;
  padding: 0;
}
.calendarcontainer .calendarHeader {
  text-align: left;
  width: 100%;
  display: table-caption;
}

.calendarcontainer .calendarHeader .Dropdown-root {
  width: 151px;
  display: inline-block;
  margin-right: 15px;
}
.calendarcontainer .calendarHeader .Dropdown-root:last-of-type {
  width: 94px;
  margin-right: 0;
}
.calendarcontainer .DayPicker-Weekday {
  padding: 6px 7.5px 8px;
}
.calendarcontainer .DayPicker-Weekday abbr[title] {
  cursor: auto;
  font-weight: 500;
  line-height: 14px;
  color: #212121;
  font-size: 14px;
  width: 20px;
  display: inline-block;
}
.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}
.calendarcontainer .DayPicker-Day--selected {
  border-radius: 0;
  border: 2px solid #002856;
  color: #002856;
  background-color: #fff;
  font-weight: 500;
  padding: 8px 5px;
}
.calendarcontainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  border-radius: 0;
  border: 2px solid #002856;
  color: #002856;
  background-color: #fff;
  font-weight: 500;
  padding: 8px 5px;
}
.calendarcontainer .DayPicker .DayPicker-Day:hover {
  background-color: #fff!important;
}
.calendarcontainer .DayPicker .DayPicker-Day {
  color: #212121;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 7.5px;
  line-height: 14px;
  width: 24px;
}
.from-text{
  margin-right: 115px;
}
.date-range-selection .Dropdown-menu{
  width: 200%;
}
.pseudo-dropdown{
  width: 25%;
  border: 2px solid #d3d3d3;
  height: 36px;
  padding: 5px 10px;
}   
.date-dropdown-open.pseudo-dropdown{
  border-color: #0052d6;
}
.date-dropdown-open.pseudo-dropdown .ga-caret-up{
  color:#0052d6;
}
.date-range-selection .ga-caret-down, 
.date-range-selection .ga-caret-up{
  margin-top: 6px;
  float: right;
  color: #616161;
  font-size: 12px;
}

.date-range-selection .radio-capsule{
  margin: 30px 12px 0 0;
}

.calendarcontainer .DayPicker .DayPicker-Day--disabled {
  color: #e0e0e0;
}
.calendarcontainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  border-radius: 0px;
  border: 2px solid #002856;
  color: #002856;
  background-color: #ffffff;
  font-weight: 500;
  padding: 8px 5px;
}
.calendarcontainer .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #ffffff;
}
.date-range-options .past-number-input .Dropdown-control{
  width: 120px;
}
.date-range-selected--disabled{
  pointer-events: none;
  opacity: 0.3;
}
.incorrect-format {
    color: #de0a01;
    font-size: 14px;
    font-weight: 400;
}
.required-input-field, .required-input-field:active, .required-input-field:focus {
  border: 2px solid #de0a01;
  background-color: #fdf3f2;
}

/* this is temp changes - need to remove once we merge the color changes from CHRO */
.main-container .btn-primary {
  background-color: #ff540a;
  border: 2px solid #ff540a;
  &:hover,
  &:active,
  &:focus,
  &:active:hover,
  &:active:focus {
    background-color: #d1460b;
    border: 2px solid #d1460b;
  }

}

/* Add this CSS to your existing stylesheet */
.subscription-credits-info-tooltip.MuiTooltip-popper .MuiTooltip-tooltip {
  font-size: 12px; /* Smaller font size */
  background-color: rgb(220, 215, 215); /* Gray background */
  color: black; /* White text color */
  padding: 5px !important; /* Adjust padding */
  border-radius: 4px; /* Rounded corners */
  max-width: 200px; /* Max width for the tooltip */
  word-wrap: break-word; /* Text wraps inside the tooltip */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Optional: slight shadow */
}
