.share-df-msg.textarea {
	background-color: #ffffff;
	padding: 12px 15px;
	border: 1px solid #e0e0e0;
	height: 120px;
	font-size: 14px;
	border-radius: 3px;
	resize: none;
	color: #616161;
	&:focus {
		border: 1px solid #0061bc;
		outline: none;
	}
	&:active {
		border: 1px solid #0061bc;
		outline: none;
	}
}
