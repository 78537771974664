@import "./var/palette";



.jumbotron-section{
  width: 100%;
  height: 85px;
  background-color: $newGartnerBlue;
  margin-bottom: 20px;
  padding: 25px 0;
 
  & .title-section{
    & .title{
      font-size: 24px;
      font-weight: 500;
      line-height: 1.04;
      color: $white;
    }
  }
  & .pull-right{
    float: right;
  }
  & .pull-left{
    float: left;
  }
}