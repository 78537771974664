

.circle-background, .circle-progress {
	fill: none;
}

.circle-background {
	stroke: #c0c0c0;
}

.circle-progress {
	stroke: #ff540a;
	stroke-linecap: round;
	stroke-linejoin: round;
}

.circle-text {
	font-size: 3em;
	font-weight: bold;
	fill: red;
}

.download-report-box svg{
	margin-top:13px
}
    