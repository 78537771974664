@import "./var/palette";
%justifyCenteringRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 20px;
}
.data-feed-table{
  margin-bottom: 138px;
  .columnHead.action-cell,.columnHead.name{
    padding: 0px;
  }
  .columnHead.frequency{
    text-align: left;
  }
  .grid{
    border: 0px;
  }
  .table-responsive{
    overflow: visible;
    thead{
      tr:last-child{
        display: none;
      }
    }
  }
  .gridcell.frequency{
    text-transform: capitalize;
  }
  .Dropdown-control{
    height: 36px;
  }
  .Dropdown-root.is-open{
    .Dropdown-control{
        border-bottom : 0px !important;
    }
  }
}
@mixin  for-desktop-up {
   @media (min-width:1200px) {@content;}
}
@mixin  for-big-desktop-up {
  @media (min-width:1800px) {@content;}
}
@mixin tableTheme($theme-name, $bottom-border, $oddBgColor) {
  .#{$theme-name} {
    overflow: hidden;
    margin-top:20px;
    & td.status , td.fileType{
      left:30px;
    }
    &  td.totalCount{
      right:16px
    }
    &  th.action-cell{
      margin-left: 20px;
    }
    & .status {
      padding-top: 0;
    }
    & th.fileType{
      left:18px;
    }
    @include for-desktop-up {
      td.status {
        left:6%;
      }
      th.status{
        left:18px;
      } 
    }
    & .frequency{
      padding: 0 10px;
    }
    & .fileType{
          
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 0;
      
        .col-title-container{
          text-align: left;
        }
    }
    & .runTime{
        padding: 0 5px;
        justify-content: flex-end;
    }
    & .lastRun{
      padding: 0 5px;
      justify-content: flex-end;
  }
  & .totalCount{
      padding: 0 5px;
      justify-content: flex-end;
  }
    & thead{
      & tr {
        display: flex;
        & th {
          padding:5px 0;
          text-align: right;
          .column-label{
            float: none !important;
            display: inline-block;
          }
          &:first-child{
            text-align: left;
            .column-label{
              float: left;
            }
          }
        }
      }

    }

    & tbody tr {
      display: flex;
      min-height: 49px;
      & .runTime{
        & .date-renderer{
          @extend %justifyCenteringRight
        }
      }
      & .action-cell {
       
        & .row-button  {
          display: none;
          position: absolute;
          top : 18px;
          right :5px;
          width: 150px;
          margin-right: 0;
          & .disabled {
            pointer-events: none;
            & .icon-animation:before{
              color:#666666;
            }
          }
        }
        justify-content: flex-end;
        padding-right: 0;
        & .tooltip-button-label{
          &.deleteDataFeed {
            right: 0;
            top: 42px;
          }
          &.shareDataFeed{
            right: 4px;
            top: 42px;
          }
          &.editDataFeed {
            right: 60px;
            top: 42px;
          }
          &.runNow {
            right: 75px;
            top: 42px;
          }
          &.copyDataFeed{
            right: 30px;
            top: 42px;
          }
        }
        & .tooltip-button{
          display: none;
        }
        & div &{
          & a{
            display: none;
          }
        }
      }
      &:hover {
        & .date{
          display: none;
        }
        & .row-button  {
          display: block;
        }
        & .action-cell {
          & .tooltip-button {
            display: inline-block;
            float: none;
              & .ga{
                padding-top: 7px;
                padding-left: 8px;
              }
          }
        }
      }
      & td{
        display: flex;
        justify-content: left;
        align-items: center;
        padding: 0;
        color: #212121;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: right;
        &:first-child{
          text-align: left;
        }
      }
    }
  }
}


@include tableTheme("table-responsive", $gray5,$gray6)