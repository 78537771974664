.progress-manager-container{
	display: flex;	
	flex-direction: column;
	height: 138px;
	padding: 12px 0;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.2);
    border-top: 3px solid #002856;
    
    .progress-indicators-container {
        display: flex;
        margin-top: 22px;
    }

    .create-data-feed-title {
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.04;
        letter-spacing: normal;
        color: #002856;
    
    }
}